<script setup>
import { inject, ref } from 'vue';
import Navigation from '@/components/Navigation';

const ctx = inject('ctx');
const $require = inject('$require');
let pageData = ref([]);

/***
 * 获取动态菜单项
 */
$require.HttpPost('/h5/payment/getPayProject')
  .then((res) => {
    if (res.data) {
      pageData.value = res.data;
    }
  });

// 跳转页面
function goUrl(url) {
  ctx.router.push(url);
}
</script>
<template>
  <div class="othFeePage">
    <div class="item-box" v-if="pageData && pageData.length > 0">
      <div
        class="item"
        @click="goUrl(`/account/promisemoney?title=${item.contract_fee_name}`)"
        v-for="(item, index) in pageData"
        :key="index"
      >
        <div>
          <!-- <img class="item-img" src="../../assets/image/my/bzj.png" alt=""> -->
          <span>
            {{
              item.contract_fee_name
            }}
          </span>
        </div>
        <img class="right" src="../../assets/image/my/y.png" alt />
      </div>
    </div>
    <emptyItem v-else></emptyItem>

  </div>
</template>

<style scoped lang="scss">
// item
.item-box {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
  border-radius: 30px;
  padding: 51px 39px 49px 41px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    height: 90px;
    border-bottom: 1px solid #efefef;

    .item-img {
      width: 48px;
      height: 48px;
      vertical-align: middle;
      margin-right: 41px;
    }

    .right {
      width: 13px;
      height: 22px;
    }
  }
}

</style>;
