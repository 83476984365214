<template lang="html">
  <van-tabbar v-model="index" active-color="#000000" inactive-color="#D2D2D2">
    <van-tabbar-item to="/home">
      <span>首页</span>
      <template #icon="props">
        <img :src="props.active ? require('../assets/image/home1.png') : require('../assets/image/home2.png')" alt="">
      </template>
    </van-tabbar-item>
    <van-tabbar-item icon="search" to="/my">
      <span>我的</span>
      <template #icon="props">
        <img :src="props.active ? require('../assets/image/my1.png') : require('../assets/image/my2.png')" alt="">
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
  export default {
    name: 'Navigation',
    props: {
      index: {
        type: Number,
        default: 0
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
